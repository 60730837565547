<template>
  <v-card
    class="rounded-lg"
    >
    <v-card-title>
      Detalle notificación
      <v-spacer></v-spacer>
      <v-btn
        icon
        @click="$emit('close')"
        >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text
      v-if="comment"
      class="pa-0"
      >
      <post-card
        tile
        outlined
        :idx="0"
        :postId="comment.postId"
        ></post-card>
      <comment-card
        tile
        style="margin-top: -1px"
        :comment="comment"
        ></comment-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { Comment } from '@/graphql/queries/posts'

const CommentCard = () => import('@/components/posts/Comment')
const PostCard = () => import('@/components/posts/Card')

export default {
  data: () => ({
    comment: null,
  }),

  props: {
    id: {
      required: true,
      type: String | Number
    }
  },

  created () {
    this.fetchComment ()
  },

  methods: {
    fetchComment () {
      this.$apollo.query({
        query: Comment,
        variables: {
          id: this.id
        }
      }).then ( res => {
        this.comment = res.data.comment
      })
    },
  },

  components: { CommentCard, PostCard }
}
</script>
